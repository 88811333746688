<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.vdp-datepicker__calendar {
    bottom: 100%;
    left: -20%;
}
.input-price input {
    text-align: right;
}
</style>
<template>
    <vx-card :title="'Detail Buying Price'">
        <div class="vx-row mb-6" style="width: 60%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>

        <hr>
        <h4 class="my-4">Head Data</h4>

        <div class="flex md:flex-row">
            <div class="w-full md:basis-1/2">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Buying Price Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input placeholder="Auto Generate" :value="this.data.bp_code" :readonly="true" class="w-full bg-readonly"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Buying Price Name</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input v-model="data.bp_name" :readonly="true" class="w-full bg-readonly"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Valid From</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row">
                            <div class="vx-col sm:w-5/12 w-full">
                                <!-- <datepicker name="date" :inline="false"
                                    v-model="data.valid_from"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        data.valid_from = null
                                    }">
                                </datepicker> -->
                                <vs-input :value="formatDate(data.valid_from)" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>
                            <div class="vx-col sm:w-2/12 w-full flex items-center text-center">
                                <span>Valid To</span>
                            </div>
                            <div class="vx-col sm:w-5/12 w-full">
                                <!-- <datepicker name="date" :inline="false"
                                    v-model="data.valid_to"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        data.valid_to = null
                                    }">
                                </datepicker> -->
                                <vs-input :value="formatDate(data.valid_to)" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>
                        </div>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Created By</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row">
                            <div class="vx-col sm:w-5/12 w-full">                        
                                <vs-input :value="this.data.created_by" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>
                            <div class="vx-col sm:w-2/12 w-full flex items-center text-center">
                                <span>Approved By</span>
                            </div>
                            <div class="vx-col sm:w-5/12 w-full">
                                <vs-input :value="this.data.approved_by" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>                    
                        </div>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Create Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row">
                            <div class="vx-col sm:w-5/12 w-full">                        
                                <vs-input :value="(this.data.created_at) != null  ? formatDate(this.data.created_at) : ''" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>
                            <div class="vx-col sm:w-2/12 w-full flex items-center text-center">
                                <span>Approved Date</span>
                            </div>
                            <div class="vx-col sm:w-5/12 w-full">
                                <vs-input :value="(this.data.approved_at) != null  ? formatDate(this.data.approved_at) : ''" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>                    
                        </div>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Currency Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- <Currency
                            placeholder="Select Currency Code"
                            :value="data.currency"
                            @selected="(val) => {
                                data.currency = val
                            }"
                        ></Currency> -->
                        <vs-input :value="data.currency" :readonly="true" class="w-full bg-readonly"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row mb-1 pl-4" v-for="(line, index) in data.notes" :key="index">
                            <p>{{ line }}</p>
                        </div>
                        <!-- <vs-textarea v-model="data.note" width="100%"/> -->
                        <!-- <div class="vs-component vs-con-textarea vs-textarea-primary" style="border: 1px solid rgba(0, 0, 0, 0.08); width: 100%;">
                            <textarea v-model="data.note" width="100%" class="vs-textarea"></textarea>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="vx-row mb-6" style="width: 60%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Attachment</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
                    </div>
                </div> -->
                <!-- <div class="vx-row mb-6" style="width: 60%" v-if="this.fileUrl != ''">
                    <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="op-block mt-5">
                            <vs-list>
                                <vs-list-item title="File 1">
                                    <div class="vx-input-group flex">
                                        <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                            title="Download" class="mr-1" @click="openTab(fileUrl)" />                                
                                    </div>
                                </vs-list-item>
                            </vs-list>
                        </div>
                    </div>
                </div> -->
                <div class="vx-row mb-6">
                    <div class="vx-col w-full flex items-center">
                        <span>Applicable to</span>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span class="ml-3">Supplier</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Supplier 
                            placeholder="Select supplier / vendor"
                            :value="data.supplier_name"
                            :disabled="true"
                            @selected="(val) => {
                                if(!id){
                                    data.supplier_id = val.id
                                    data.supplier_name = val.name
                                }
                            }"
                        ></Supplier>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span class="ml-3">Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Warehouse 
                            placeholder="Select warehouse"
                            :disabled="true"
                            :value="warehouses"
                            :multiple="true"
                            @selected="(val) => {                        
                                changeWh(val)                        
                            }"
                        ></Warehouse>
                    </div>
                </div>
            </div>
            <div class="pl-6 w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                </div>
                <div class="mb-6 vx-row w-1/2">
                    <vs-list>
                        <vs-list-item v-for="(item, index) in data.attachment" :key="index"
                            :title="item.name" class="flex">
                            <div class="flex">
                                <a @click.stop="downloadFileAwsS3(item.path)"><vs-button color="success" type="flat"
                                        icon="file_download"></vs-button></a>
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>

        <!-- <div class="vx-row mb-6 mt-3" style="width:70%" v-if="this.statusTab != 'detail' && this.id">
            <vs-button class="mr-3 ml-4" color="primary" @click="handleSubmit()">Save Changes</vs-button>
        </div> -->

        <hr>
        <h4 class="my-4">Lines Data</h4>
        
        <div class="vx-row mb-6 mt-3 flex items-center" style="width:70%">
            <p style="font-weight: 600;" class="ml-4">Include Conversion</p>
            <vs-switch v-model="switchC" class="ml-2">
                <span slot="on">On</span>
                <span slot="off">Off</span>
            </vs-switch>            
        </div>        

        <div class="vx-row mb-6 mt-10">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="11%">SKU Code</th>
                            <th width="20%">SKU Name</th>
                            <th>Note</th>
                            <th>Qty</th>
                            <th width="7%">HU</th>
                            <th>Amount</th>
                            <th>Valid From</th>
                            <th>Valid to</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, index) in lines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <!-- <ItemSelect
                                    placeholder="Select SKU Code"
                                    :value="line.sku_code"
                                    @selected="(val) => {
                                        line.sku_code = val
                                    }"
                                ></ItemSelect> -->
                                <vs-input v-model="line.sku_code" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.sku_name" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.note" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" v-model="line.qty" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.unit" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td input-price vs-table--td" style="padding: 5px">
                                <vs-input type="text" v-model="line.price" :readonly="true" @input="formatNumber(line)" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <!-- <datepicker name="date" :inline="false"
                                    v-model="line.valid_from"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        line.valid_from = null
                                    }">
                                </datepicker> -->                                
                                <vs-input :value="formatDate(line.valid_from)" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <!-- <datepicker name="date" :inline="false"
                                    v-model="line.valid_to"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        line.valid_to = null
                                    }">
                                </datepicker> -->
                                <vs-input :value="formatDate(line.valid_to)" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <!-- <td class="td vs-table--td" style="text-align: center">
                                <div class="flex vx-input-group">
                                    <vs-button v-if="line.dupe" @click.stop="removeItemsRow(index)" size="small" color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px" title="Remove Row" />
                                    <vs-button v-else @click.stop="addItemsRow(index)" size="small" color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div
                style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <!-- <div v-if="this.statusTab != 'Release'">
                        <vs-button v-if="this.id" class="mr-3 mb-2" @click="handleSubmit">Update</vs-button>
                        <vs-button v-else class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    </div> -->
                </div>
            </div>
        </div>
        
        <vs-prompt
            @cancel="val=''"
            @accept="uploadFile"
            :active.sync="activePrompt"
            :title="'Upload Items'"
            :accept-text="'Submit'">
            <div class="con-exemple-prompt">
                <label for="fileInput" class="font-bold">File to upload</label>
                <input
                    id="fileInput"
                    name="file"
                    class="w-full inputx my-2"
                    type="file"
                    ref="file"
                    accept=".xlsx, .xls"
                    @change="handleChangeFile"
                />                
            </div>
        </vs-prompt>
    </vx-card>
</template>

<script>

import _ from "lodash";
import Date from "@/components/Date.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import Warehouse from "@/components/Warehouse.vue";
import Supplier from "@/components/Supplier.vue";
import Currency from "@/components/Currency.vue";
import ItemSelect from "./component/ItemSelect.vue";
import vSelect from "vue-select";

export default {
    components: {
        Warehouse,
        Supplier,
        Currency,
        ItemSelect,
        Date,
        vSelect,
        Datepicker
    },
    data() {
        return {
            id: null,
            activePrompt : false,
            file: null,
            data: {
                bp_id: null,
                bp_code: null,
                bp_name: null,
                valid_from: null,
                valid_to: null,
                created_by: null,
                created_at: null,
                approved_by: null,
                approved_at: null,
                currency: null,
                note: null,                
                notes: [],                
                supplier_id: null,
                supplier_name: null,
                attachment: []
            },
            lines: [],
            warehouses: [],
            statusReadonly: false,
            statusTab: null,
            is_approval: false,
            switchC: false,
        }
    },
    watch: {
        switchC(newVal, oldVal) {
            this.getData()
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.statusTab = this.$route.params.tab
        if(this.statusTab == undefined){
            this.statusTab = "create"
        }
        console.log("tab : "+this.statusTab)
        if (this.$route.params.is_approval){
            this.is_approval = true
        }
        if (this.id) {
            this.getData()
        }else{
            const newItem = {
                sku_code: null,
                sku_name: null,
                unit: null,
                qty: null,
                price: null,
                valid_from: null,
                valid_to: null
            }
            this.lines.push(newItem)
        }
    },
    methods: {
        changeWh(val){
            this.warehouses = val            
        },
        getData() {
            console.log(this.statusTab)
            this.$vs.loading();            
            this.data = {};
            this.warehouses = [];
            this.lines = [];
            this.$http
                .get(this.$store.state.master.buyingPrice.baseUrlPath + "/" + this.id, {
                    params: {
                        tab: this.statusTab == "Expired" ? this.statusTab : "",
                    }
                })
                .then((resp) => {
                    console.log(resp)
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        var data_head = resp.data.data_head[0]
                        var created = resp.data.created[0]
                        this.data.bp_id = data_head.id
                        this.data.bp_code = data_head.code
                        this.data.bp_name = data_head.name
                        this.data.valid_from = data_head.valid_from
                        this.data.valid_to = data_head.valid_to
                        this.data.currency = data_head.currency_code
                        this.data.created_at = data_head.created_at
                        this.data.created_by = created.name
                        this.data.supplier_id = data_head.supplier_id
                        this.data.supplier_name = data_head.supplier_name
                        this.data.notes = data_head.notes.split(";")
                        this.data.attachment = resp.data.attachment

                        var approval = resp.data.approval
                        if (data_head.status == "released" || data_head.status == "expired") {
                            if (approval.length != 0) {
                                this.data.approved_by = approval[0].username
                                this.data.approved_at = approval[0].updated_at
                            }                            
                        }

                        resp.data.data_wh.forEach((el, idx) => {
                            const newItem = {
                                id: el.warehouse_id,
                                label: el.code +" "+ el.name
                            };
                            this.warehouses.push(newItem);
                        });

                        var isConversion = this.switchC

                        resp.data.data_line.forEach((el, idx) => {
                            if (isConversion || (!isConversion && el.is_conversion == 0)){
                                const newItem = {
                                    sku_code: el.sku_code,
                                    sku_name: el.item_name,
                                    note: el.note,
                                    unit: el.unit_name,
                                    qty: el.qty_from +' - '+ el.qty_to,
                                    price: el.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    valid_from: el.valid_from,
                                    valid_to: el.valid_to,
                                    dupe: (idx == 0) ? false : true
                                }
                                this.lines.push(newItem)
                            }
                        })                        
                    }
                });
        },
        addItemsRow(index) {
            this.lines[index].has_child = true            
            const newItem = Object.assign({}, this.lines[index])
            newItem.dupe = true
            newItem.sku_code = null
            newItem.sku_name = null
            newItem.unit = null
            newItem.qty = null
            newItem.price = null
            newItem.valid_from = null
            newItem.valid_to = null
            this.lines.splice(this.lines.length + 1, 0, newItem)
        },
        removeItemsRow(index) {
            this.lines.splice(index, 1)
            this.lines[index - 1].has_child = false
        },
        formatNumber(line) {
            var amount = parseFloat(line.price.replace(/[^\d.-]/g, ''))
            if (!isNaN(amount)){
                line.price = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }else {
                line.price = 0
            }
        },
        handleBack() {
            if(!this.is_approval){
                this.$router.push({
                    name: this.$store.state.master.buyingPrice.baseRouterName,
                });
            }else {
                this.$router.push({
                    name: this.$store.state.master.buyingPrice.baseRouterName+".approval",
                });
            }
        },
        downloadFile(){
            this.$vs.loading();
            this.$http
            .get(this.$store.state.master.buyingPrice.baseUrlPath + "/excel-edit", {
                params: {
                    buyingPriceId: this.data.bp_id,
                    supplierId: this.data.supplier_id,
                    warehouseId: this.data.warehouse_id
                },
                responseType: "blob",
            })
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.type == "application/json"){
                    this.$vs.notify({
                        title: "Error",
                        text: "Items for supplier "+this.data.supplier_name+" and warehouse "+this.data.warehouse_name+" not found",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x",
                        time: "10000",
                    });
                }else{                    
                    var fileURL = window.URL.createObjectURL(resp);
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        "download", "edit-buying-price.xlsx"
                    );                
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
        },
        handleUpload() {
            this.activePrompt = true
            this.file = null
        },
        handleChangeFile(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.readAsBinaryString(this.file);
            }
        },
        uploadFile() {
            if (!this.file) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                    time: "10000",
                });
            } else {
                let form = new FormData();
                form.append("file", this.file);
                form.append("buyingPriceID", this.id);
                form.append("supplierID", this.data.supplier_id);

                this.$vs.loading();
                this.$http
                    .post(this.$store.state.master.buyingPrice.baseUrlPath + "/import-edit", form)
                    .then((resp) => {                        
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.getData()
                            this.$vs.notify({
                                title: "Success",
                                text: "File Uploaded Successfully",
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });
                        } else {                            
                            this.$vs.notify({
                                title: "Error",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x",
                                time: "10000",
                            });
                        }                    
                    });
            }
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit() {
            let formData = new FormData();
            this.data.valid_from = moment(this.data.valid_from).format('YYYY-MM-DD')
            this.data.valid_to = moment(this.data.valid_to).format('YYYY-MM-DD')
            formData.append("data", JSON.stringify(this.data))
            formData.append("warehouses", JSON.stringify(this.warehouses))

            if (this.data.bp_name == ""){
                this.$vs.notify({
                    title: "Error",
                    text: "Buying price name is required",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                return
            }

            this.$vs.loading();
            this.$http
                .post(this.$store.state.master.buyingPrice.baseUrlPath, formData)
                .then((resp) => {                        
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.getData()
                        this.$vs.notify({
                            title: "Success",
                            text: resp.message,
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {                            
                        this.$vs.notify({
                            title: "Error",
                            text: resp.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x",
                            time: "10000",
                        });
                    }                    
                });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    computed:{
        formatDate: () => {
            return (val) => {
                if (!val || !moment.utc(val).isValid()) {
                    return ''; // Return empty string for invalid date or empty input
                }
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formattedNumber() {
            const formattedValue = parseFloat(this.rawInput).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });

            return formattedValue;
        },
    }    
}
</script>